export default function ({ app }) {
    const hybridNavigation = app.$cookies.get('hybrid') === 'true';

    const routerPush = app.router.push;

    const shouldExecuteBrowserNavigation = location => {
        // If prerequisites are not met, return false
        if (!hybridNavigation || !location.params) {
            return false;
        }

        const { region, language } = location.params;
        const homePath = region && language ? `/${region}/${language}/` : region ? `/${region}/` : '/';

        const isHomePath = location.path === homePath;
        const isBrandPath = location.params?.brand;
        const isCategoryPath = location.params?.slug;

        // If the path is the home path, a brand path or a category path, return true
        return isHomePath || isBrandPath || isCategoryPath;
    };

    // Override the router push method
    app.router.push = function (...args) {
        try {
            const location = args[0];
            // Force certain routes to be browser navigation
            const shouldBrowserNavigate = shouldExecuteBrowserNavigation(location);

            if (shouldBrowserNavigate) {
                window.location.href = location.path;
                return;
            }
        } catch (error) {
            app.$sentry.captureException(error);
        }

        // Call the original router push method if an error occurs
        routerPush.apply(this, args);
    };
}
